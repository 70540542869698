.max-width-45em {
  max-width: 45em !important;
}

.pre-wrap {
  white-space: pre-wrap !important;
}

.full-width {
  width: 100% !important;
}

.width-10em {
  width: 15em !important;
}

.width-220px {
  width: 220px !important;
}

.margin-top-1em {
  margin-top: 1em !important;
}

.margin-bottom-1em {
  margin-bottom: 1em !important;
}

.padded {
  padding-top: 5em !important;
  padding-bottom: 5em !important;
}

.small-padded {
  padding-top: 2.5em !important;
  padding-bottom: 2.5em !important;
}

.vertical-align-bottom {
  vertical-align: bottom !important;
}

.color-grey {
  color: #999 !important;
}

.color-white {
  color: white !important;
}

.color-red {
  color: #db2828 !important;
}

.bottom-padded {
  padding-bottom: 5em !important;
}

html {
  scroll-behavior: smooth;
}

.side-padded {
  padding-left: 5em !important;
  padding-right: 5em !important;
}

.margin-top-5em {
  margin-top: 5em !important;
}

.color-dark-grey {
  color: #444 !important;
}

.color-white {
  color: #FFF !important;
}

.menu.transition {
  background-color: black !important;
}

.selection > .menu.transition {
  background-color: white !important;
}

.float-right {
  float: right !important;
}

.color-dark-red {
  color: #d01919!important;
}

.bg-color-light-grey {
  background-color: #EEE !important;
}

p {
  font-size: 1.33em !important;
}

font-size-1em {
  font-size: 1em !important;
}

.label {
  display: block;
  margin: 2em 0.5em 0.5em 0.5em;
  color: rgba(0,0,0,.87);
  font-size: 1.2em;
  font-weight: 700;
  text-transform: none;
}

.label-no-margin {
  margin: 0.5em 0.5em 0.5em 0.5em !important;
  padding-top: 5em !important;
  color: rgba(0,0,0,.87);
  font-size: 1em;
  font-weight: 700;
  text-transform: none;
}

textarea {
  margin-bottom: 2em !important;
}

.border-bottom-red {
  border-bottom: solid #d01919 2.5px !important;
}

.text-align-center {
  text-align: center !important;
}

.text-align-left {
  text-align: left !important;
}

.z-index-3 {
  z-index: 3 !important;
}

.Toastify__progress-bar--default {
  background: linear-gradient(to right, orange, #ff2d55) !important;
}

.menu > a {
  padding-top: 0.1em !important;
}

.ui.secondary.pointing.menu .active.item {
  font-weight: 400 !important;
}

.logoImg {
  width: 4em !important;
}

p {
  text-align: justify !important;
  text-justify: inter-character !important;
}

#root > div:nth-child(5) > div > div {
  display: inline-block;
  margin-right: 5em;
}

.padded-sides {
  padding-left: 3em !important;
  padding-right: 3em !important;
}

.ui.secondary.menu .dropdown.item>.menu, .ui.text.menu .dropdown.item>.menu {
  margin-top: 0 !important;
}